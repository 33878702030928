var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"observer",staticClass:"w-full text-left",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
return [(!_vm.cuenta_prima_creada)?_c('div',{staticClass:"column result my-1"},[_c('b-collapse',{attrs:{"animation":"slide","open":_vm.isOpen},on:{"update:open":function($event){_vm.isOpen=$event}},scopedSlots:_vm._u([{key:"trigger",fn:function(props){return _c('div',{attrs:{"role":"button"}},[_c('div',{staticClass:"columns card-header shadow-none mt-0 is-mobile"},[_c('div',{staticClass:"column flex flex-col justify-center"},[_c('p',{staticClass:"text-xl"},[_vm._v("Asignar garantía fondeo")])]),_c('div',{staticClass:"column is-4 flex items-center justify-end"},[_c('b-icon',{attrs:{"icon":props.open ? 'chevron-down' : 'chevron-right'}})],1)])])}}],null,true)},[_c('div',{staticClass:"has-border-radius mt-3 shadow-none z-0"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-6"},[_c('valid-select',{attrs:{"rules":_vm.isOpen ? 'required' : '',"label":"Garantia de portafolio","placeholder":"Seleccionar...","data":_vm.getTiposGarantiaPortafolio,"show":"nombre","realValue":"id","expanded":true,"disabled":true},model:{value:(_vm.tipos_garantia_portafolio),callback:function ($$v) {_vm.tipos_garantia_portafolio=$$v},expression:"tipos_garantia_portafolio"}})],1),_c('div',{staticClass:"column is-6"},[_c('ValidInput',{attrs:{"rules":_vm.isOpen ? 'required' : '',"label":"Id de garantia","placeholder":"Identificador de la garantia","type":"text","expanded":true},model:{value:(_vm.identificador_garantia),callback:function ($$v) {_vm.identificador_garantia=$$v},expression:"identificador_garantia"}})],1)]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-6"},[(
                _vm.tipos_garantia_portafolio !== 16 &&
                  _vm.tipos_garantia_portafolio != null
              )?_c('ValidInput',{attrs:{"rules":_vm.isOpen ? 'required|min_value:0.00' : '',"label":"Cobertura efectiva","placeholder":"0.00","type":"number","expanded":true,"addon":"","addonIcon":"percentage"},model:{value:(_vm.cobertura_efectiva),callback:function ($$v) {_vm.cobertura_efectiva=$$v},expression:"cobertura_efectiva"}}):_vm._e()],1),_c('div',{staticClass:"column is-6"},[(
                _vm.tipos_garantia_portafolio !== 16 &&
                  _vm.tipos_garantia_portafolio != null
              )?_c('ValidInput',{attrs:{"rules":_vm.isOpen ? 'required|min_value:0.00' : '',"label":"Costo Prima del fondeador","placeholder":"0.00","type":"number","expanded":true,"addon":"","addonIcon":"percentage"},model:{value:(_vm.prima_fondeador),callback:function ($$v) {_vm.prima_fondeador=$$v},expression:"prima_fondeador"}}):_vm._e()],1)]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-6"},[(
                _vm.tipos_garantia_portafolio !== 16 &&
                  _vm.tipos_garantia_portafolio != null
              )?_c('ValidInput',{attrs:{"rules":_vm.isOpen ? 'required|min_value:0.00' : '',"label":"Costo Prima al acreditado","placeholder":"0.00","type":"number","expanded":true,"addon":"","addonIcon":"percentage"},model:{value:(_vm.prima_acreditado),callback:function ($$v) {_vm.prima_acreditado=$$v},expression:"prima_acreditado"}}):_vm._e()],1),_c('div',{staticClass:"column is-6"},[(
                _vm.tipos_garantia_portafolio !== 16 &&
                  _vm.tipos_garantia_portafolio != null
              )?_c('valid-input',{attrs:{"label":"Notas","rules":_vm.isOpen ? 'required' : '',"placeholder":"Notas...","type":"textarea","expanded":true},model:{value:(_vm.notas),callback:function ($$v) {_vm.notas=$$v},expression:"notas"}}):_vm._e()],1)]),_c('div',{staticClass:"columns mt-4"},[_c('div',{staticClass:"column"},[_c('b-button',{staticClass:"font-bold has-background-grey-darker has-text-white",attrs:{"type":"is-primary","expanded":""},on:{"click":function($event){validate().then(function (result) { return _vm.asignar(result); })}}},[_vm._v(" Guardar asignación ")])],1)])])])],1):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }