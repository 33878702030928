<template>
  <div>
    <div class="max-h-22rem overflow-y-auto">
      <div
        class="has-background-white has-border-radius shadow-none p-5 columns mb-4 is-multiline m-0"
        v-for="(disposicion, index) in disposiciones"
        :key="index"
      >
        <div class="column pb-0">
          <p class="text-xl">
            <b>Folio</b>
            #{{ idToShow(disposicion) }}
          </p>
        </div>
        <div class="column text-right pb-0">
          <p class="text-2xl font-bold">
            {{ disposicion.capital | currency | hp }}
            <span class="text-sm font-200">{{ disposicion.moneda.clave }}</span>
          </p>
        </div>
        <div class="column is-12 pt-0">
          <p>{{ disposicion.producto_financiero.alias }}</p>
        </div>
      </div>
    </div>
    <div class="mx-0 mt-4 columns rule-grey">
      <div class="column">
        <p class="text-2xl font-bold">Total:</p>
      </div>
      <div class="column text-right">
        <p class="font-bold text-2xl">{{ total | currency | hp }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "listDisposicionCards",
  props: {
    disposiciones: Array,
    total: Number
  }
};
</script>
