<template>
  <div class="has-border-radius p-4">
    <div class="columns">
      <div class="column">
        <p
          class="font-bold has-text-grey-darker sm:text-xl"
          :class="{ 'mb-6': !paraEntregar }"
        >
          Accesorios
        </p>
      </div>
    </div>
    <div v-if="accesorios && accesorios.length > 0 || cuentas && cuentas.length > 0">
      <div class="columns is-mobile" :class="{ 'text-sm': paraEntregar }">
        <div class="column is-1" v-if="paraEntregar"></div>
        <div class="column is-1">FOLIO</div>
        <div class="column is-2">ACCESORIO</div>
        <div class="column is-3">VALOR</div>
        <div
          class="column"
          :class="{ 'is-4': !paraEntregar, 'is-3': paraEntregar }"
        >
          FECHA VENCIMIENTO
        </div>
        <div class="column is-1">IVA</div>
      </div>
      <div
        class="columns is-mobile has-background-grey-lighter has-border-radius mb-6"
        :class="{ 'text-sm': paraEntregar, 'font-bold': !paraEntregar }"
        v-for="costo in accesorios"
        :key="costo.id"
      >
        <div class="column is-1" v-if="paraEntregar">
          <b-checkbox v-model="costo.checkbox" @input="sendToParent(true)" />
        </div>
        <div class="column has-text-grey-darker is-1">
          {{ costo.id }}
        </div>
        <div class="column has-text-grey-darker is-2">
          {{ costo.catalogo_operaciones.nombre }}
        </div>
        <div class="column has-text-grey-darker is-3">
          <span v-if="costo.catalogo_operaciones.tipos_pago_ce_id == 1">
            {{ (costo.valor * 100) / capital  }}% ({{
              costo.valor | currency | hp
            }}
            {{ moneda }})
          </span>
          <span v-else>{{ costo.valor | currency | hp }} {{ moneda }}</span>
        </div>
        <div
          class="column has-text-grey-darker"
          :class="{ 'is-4': !paraEntregar, 'is-3': paraEntregar }"
        >
          {{ costo.fecha_vencimiento | moment("DD-MM-YYYY") }}
        </div>
        <div class="column has-text-grey-darker">
          {{ calcularIva(costo) | currency | hp }} {{ moneda }}
        </div>
      </div>
      <div
        class="columns is-mobile has-background-grey-lighter has-border-radius mb-6"
        :class="{ 'text-sm': paraEntregar, 'font-bold': !paraEntregar }"
        v-for="cuenta in cuentas"
        :key="cuenta.id"
      >
        <div class="column is-1" v-if="paraEntregar">
          <b-checkbox v-model="cuenta.checkbox" @input="sendToParent(false)" />
        </div>
        <div class="column has-text-grey-darker is-3">
          {{ cuenta.tipo_de_operacion.nombre }}
        </div>
        <div class="column has-text-grey-darker is-3">
          <span>{{ cuenta.cantidad | currency | hp }} {{ moneda }}</span>
        </div>
        <div
          class="column has-text-grey-darker"
          :class="{ 'is-4': !paraEntregar, 'is-3': paraEntregar }"
        >
          {{ cuenta.fecha_vencimiento | moment("DD-MM-YYYY") }}
        </div>
        <div class="column has-text-grey-darker">
          {{ cuenta.iva | currency | hp }} {{ moneda }}
        </div>
      </div>
    </div>
    <div v-else>
      <p class="has-text-danger">
        Por el momento no existen accesorios asociados a esta disposición
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "AccesorioCard",
  props: {
    accesorios: Array,
    cuentas: Array,
    capital: Number,
    moneda: String,
    iva: {
      type:Number,
      default:.16
    },
    paraEntregar: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    calcularIva(accesorio){
      let iva = 0;
      if(accesorio.iva == 0){
        return iva;
      }
      if(accesorio.catalogo_operaciones.tipos_pago_ce_id == 1){
        iva = this.iva * accesorio.valor;
      }else{
        iva = accesorio.valor * this.iva;
      }
      return iva;
    },
    //Envia al parent que el accesorio ha sido checkeado
    sendToParent(costo_extra) {
      if(costo_extra){
        let accesorios = this.accesorios.filter(accesorio => {
          if (accesorio.checkbox) {
            return accesorio.id;
          }
        });
        accesorios = accesorios.map(x => x.id);
        this.$emit("checked", accesorios, true);
      }else{
        let cuentas = this.cuentas.filter(cuenta => {
          if (cuenta.checkbox) {
            return cuenta.id;
          }
        });
        cuentas = cuentas.map(x => x.id);
        this.$emit("checked", cuentas, false);
      }
    }
  }
};
</script>
