<template>
  <ValidationObserver
    ref="observer"
    v-slot="{ validate }"
    class="w-full text-left"
  >
    <p class="text-3xl font-medium text-left has-text-grey-darker">
      Ligar línea de crédito
    </p>
    <div class="mb-3 has-text-grey-darker text-left">
      Estas a punto de ligar una línea de crédito a
      <span v-if="Array.isArray(disposicion_id)">{{ arrendamiento ? 'los arrendamientos' : 'las disposiciones'}}</span>
      <span v-else>{{ arrendamiento ? 'el arrendamiento': 'la disposición' }}</span>, al realizar esta acción cambiara el
      estatus de
      <span v-if="Array.isArray(disposicion_id)">{{ arrendamiento ? 'los arrendamientos' : 'las disposiciones'}}</span>
      <span v-else>{{ arrendamiento ? 'el arrendamiento': 'la disposición' }} #{{ disposicion_id }} </span> de
      <span class="font-bold">"Prospecto"</span> a
      <span class="font-bold">"En Espera"</span>
      <p class="italic">Es necesario llenar los siguientes campos</p>
    </div>
    <ValidSelect
      rules="required"
      label="Destino de los recursos"
      placeholder="Selecciona uno..."
      v-model="destino_recursos"
      fieldClass="has-text-grey-darker"
      :data="getDestinoRecursos"
      show="nombre"
      :expanded="true"
      realValue="id"
    />
    <valid-auto-complete
      rules="required"
      label="Acreditado"
      placeholder="Buscar..."
      v-model="acreditado"
      fieldClass="mt-4"
      :data="getClientesLineasCreditoProducto"
      show="alias"
      :expanded="true"
      @select="loadLineas"
      :disabled="is_acreditado"
    >
      <template v-slot:custom="slotProps">
        <div class="flex items-center">
          <b-icon
            class="has-text-primary"
            :class="{
              'has-text-grey-lighter': slotProps.option.option.checked === null
            }"
            :icon="
              slotProps.option.option.checked === null
                ? 'circle'
                : 'check-circle'
            "
          />
          <span class="has-text-grey-darker-always ml-2">{{
            slotProps.option.option.alias
          }}</span>
        </div>
      </template>
    </valid-auto-complete>
    <valid-auto-complete
      rules="required"
      label="Línea de crédito"
      placeholder="Buscar..."
      v-model="linea_id"
      :data="getLineasCliente"
      fieldClass="mt-4"
      show="id"
      :expanded="true"
      v-if="getLineasCliente"
      :custom="true"
      :disabled="is_linea"
    >
      <template v-slot:custom="slotProps">
        <div>
          <p
            :class="{
              'has-text-primary-darker': slotProps.option.option.pasiva == 0,
              'has-text-pasivo': slotProps.option.option.pasiva == 1
            }"
          >
            <b>Línea #{{ slotProps.option.option.id }}</b> -
            {{ slotProps.option.option.producto_financiero.alias }}
          </p>
          <p>
            Capital autorizado:
            {{ slotProps.option.option.capital_autorizado | currency | hp }}
          </p>
          <p>
            Capital disponible:
            <span
              :class="{
                'has-text-danger':
                  slotProps.option.option.capital_disponible == 0
              }"
              class="font-bold"
              >{{
                slotProps.option.option.capital_disponible | currency | hp
              }}</span
            >
          </p>
        </div>
      </template>
    </valid-auto-complete>
    <valid-select
      rules="required"
      label="Estado"
      placeholder="Selecciona uno..."
      v-model="estado"
      fieldClass="has-text-grey-darker"
      :data="getEstados"
      show="nombre"
      :expanded="true"
      realValue="id"
      @input="loadMunicipios"
    />
    <valid-auto-complete
      rules="required"
      label="Municipio"
      v-if="getMunicipios"
      placeholder="Selecciona uno..."
      v-model="municipio"
      fieldClass="has-text-grey-darker"
      :data="getMunicipios"
      show="nombre"
      :expanded="true"
      realValue="id"
      @select="loadLocalidades"
    />
    <valid-auto-complete
      rules="required"
      label="Localidad"
      v-if="getLocalidades"
      placeholder="Selecciona uno..."
      v-model="localidad_id"
      fieldClass="has-text-grey-darker"
      :data="getLocalidades"
      show="nombre"
      :expanded="true"
      realValue="id"
      @select="setLocalidad"
    />
    <div class="columns mt-10">
      <div class="column is-3">
        <b-button
          type="is-white"
          class="has-text-danger font-bold"
          expanded
          @click="$emit('cancelForm')"
          >Cancelar</b-button
        >
      </div>
      <div class="column">
        <b-button
          type="is-primary"
          expanded
          class="font-bold"
          @click="validate().then(result => changeStatus(result))"
          >Ligar línea de crédito</b-button
        >
      </div>
    </div>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import ValidAutoComplete from "@/components/form/ValidAutoComplete";
import ValidSelect from "@/components/form/ValidSelect";
import { mapGetters } from "vuex";
export default {
  name: "ProspectoEnEspera",
  components: {
    ValidationObserver,
    ValidAutoComplete,
    ValidSelect
  },
  props: {
    disposicion_id: [Array, Number],
    arrendamiento: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      acreditado: "",
      linea_id: "",
      disable_linea: true,
      acreditado_id: null,
      destino_recursos: null,
      is_linea: false,
      is_acreditado: false,
      estado: null,
      municipio: null,
      localidad_id: null,
      localidad_inegi: null
    };
  },
  methods: {
    /*
      Cambia el estatus de la disposición al indicado
      es posible hacerlo masivamente si el prop disposicion_id es un Array
      @result (Boolean): resultado de las validaciones de inputs
    */
    changeStatus(result) {
      if (!result) return;
      if (Array.isArray(this.disposicion_id)) {
        let ids = this.disposicion_id.map(disp => disp.id);
        const data = {
          acreditado_id: this.acreditado_id,
          linea_credito_id: this.linea_id,
          id: ids,
          destino_recursos_id: this.destino_recursos,
          localidad_id: this.localidad_inegi
        };
        this.$store.dispatch("disposicion/changeProspectoEsperaMasivo", data);
      } else {
        const data = {
          acreditado_id: this.acreditado_id,
          linea_credito_id: this.linea_id,
          id: this.disposicion_id,
          destino_recursos_id: this.destino_recursos,
          localidad_id: this.localidad_inegi
        };
        this.$store.dispatch("disposicion/changeProspectoEspera", data);

        if (this.arrendamiento && this.getDisposicionDetalle) {
          this.agregarActivo(this.getDisposicionDetalle.garantias[0]);
        }
      }
    },
    //Carga las lineas de credito del acreditado seleccionado
    loadLineas(acreditado) {
      this.acreditado_id = acreditado.id;
      this.$store
        .dispatch("lineascredito/getLineasByPersona", {
          persona_id: acreditado.id,
          producto_financiero_id: this.getDisposicionDetalle.producto_financiero
            .id
        })
        .then(res => {
          if (!res || res.length == 0) {
            this.notification(
              "warning",
              "Cliente sin línea de crédito activa",
              `El cliente seleccionado no tiene ninguna línea de crédito activa`
            );
          } else {
            this.disable_linea = false;
          }
        });
    },
    loadMunicipios() {
      this.$store.dispatch("catalogos/getMunicipios", this.estado);
    },
    loadLocalidades(val) {
      this.$store.dispatch("catalogos/getLocalidades", val.id);
    },
    setLocalidad(val) {
      this.localidad_inegi = val.id;
    },
    //Cuando es un arrendamiento y el activo no esta ligado a una línea se utiliza esta función para ligar el activo a la línea
    async agregarActivo(activo) {
      let prelacion = 0;
      const garantia = activo;
      if (garantia.data && garantia.data.atributos.length > 0) {
        const prelacionGarania = garantia.data.atributos.find(atributo => atributo.nombre_atributo == 'Prelación');
        prelacion = prelacionGarania ? prelacionGarania.valor : 0;
      }

      const linea_id = this.linea_id;
      const lineas_credito = {};
      lineas_credito[linea_id] = { prelacion };
      
      this.$store
        .dispatch("lineascredito/asignarGarantiaExistente", {
          garantia_id: garantia.id,
          lineas_credito
        }
      );
    }
  },
  computed: {
    ...mapGetters("catalogos", [
      "getClientesLineasCreditoProducto",
      "getDestinoRecursos",
      "getEstados",
      "getMunicipios",
      "getLocalidades"
    ]),
    ...mapGetters("lineascredito", ["getLineasCliente"]),
    ...mapGetters("disposicion", ["getDisposicionDetalle"])
  },
  mounted() {
    if (this.getDisposicionDetalle.localidad) {
      this.$store.dispatch("catalogos/getMunicipios", this.estado_id);
      this.$store.dispatch("catalogos/getLocalidades", this.municipio_id);
      this.estado = this.getDisposicionDetalle.localidad.estado_id;
      this.municipio = this.getDisposicionDetalle.localidad.municipio.nombre;
      this.localidad_inegi = this.getDisposicionDetalle.localidad.id;
      this.localidad_id = this.getDisposicionDetalle.localidad.nombre;
    }
    if (this.getDisposicionDetalle && !Array.isArray(this.disposicion_id)) {
      if (this.getDisposicionDetalle.linea_credito.length > 0) {
        this.linea_id = this.getDisposicionDetalle.linea_credito[0].id;
        this.is_linea = true;
      }
      if (this.getDisposicionDetalle.acreditado) {
        this.acreditado = this.getDisposicionDetalle.acreditado.nombre_completo.split(
          "|"
        )[0];
        this.loadLineas({
          id: this.getDisposicionDetalle.acreditado.id
        });
        this.is_acreditado = true;
      }
    }
    if (this.getClientesLineasCreditoProducto == null) {
      this.$store.dispatch("catalogos/getClientesLineasCreditoProducto",this.getDisposicionDetalle.producto_financiero
            .id);
    }
    if (this.getDestinoRecursos == null) {
      this.$store.dispatch("catalogos/getDestinoRecursos");
    }
    if (this.getEstados == null) {
      this.$store.dispatch("catalogos/getEstados");
    }
  }
};
</script>
